import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col, Table, message, Spin, Tag} from 'antd';
import {Context} from '../../AppContext';
import Quotation from '../../Models/Quotation';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit, Add, Refresh} from '@styled-icons/material';
import QuotationForm from '../../Forms/QuotationForm';
import QuotationCompleteForm from '../../Forms/QuotationCompleteForm';
import {fillZero, previousPage, quotationUtil, date} from '../../Utils';
import {Button} from '../../Widgets';
import useQuotation from '../../hooks/use-quotation';
import CustomerData from '../../Components/CustomerData';
import DispatchSelect from '../../Components/DispatchSelect';
import ProjectItem from '../../Models/ProjectItem';
import useQuotationItems from '../../hooks/use-quotation-items';
import ItemTable from './ItemTable';
import useBillingList from '../../hooks/use-billing-list';
import Billing from '../../Models/Billing';
const queryString = require('query-string');
const appConfig = require('../../data.json');

const labels = Quotation.labels;

const dbg = {
  id: '', // 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function QuotationDetailPage(props) {
  const {id = dbg.id, c} = queryString.parse(props.location.search);
  //id = Quotation.id , p = project.id
  const isEdit = !!id;
  const app = useContext(Context);
  const {
    loading,
    record,
    setRecord,
    getRecord,
    items,
    setItem,
    customer,
    create,
    edit,
    newVersion,
    complete,
  } = useQuotation({
    customerId: c,
    id,
  });

  const {
    loading: itemLoading,
    createItem,
    projects,
    setProjects,
    dispatches,
    setDispatches,
  } = useQuotationItems({quotation: record});

  const {records: billings, loading: billingLoading, toVoid} = useBillingList({
    query: {quotation: id},
  });

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage()}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />

        <Button
          disabled={!isEdit}
          onClick={() => {
            app.actions.setLoading(true);
            navigate(`/pdf?type=quotation&id=${record.id}`);
          }}>
          匯出報價單
        </Button>
      </div>
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <Block title="報價單資訊">
            {record && <QuotationForm record={record} setRecord={setRecord} />}
          </Block>
        </Col>
        <Col span={24}>
          <Spin spinning={loading || itemLoading}>
            <Block
              title="報價項目"
              contentStyle={{padding: 0}}
              actions={[
                {
                  icon: <Add size="16" color="505050" />,
                  onClick: () =>
                    app.actions.setModal(
                      <DispatchSelect
                        setRecord={async ({project, dispatch}) => {
                          setProjects((prev) => ({
                            ...prev,
                            [project.id]: project,
                          }));
                          setDispatches((prev) => ({
                            ...prev,
                            [dispatch.id]: dispatch,
                          }));

                          let newItems = await createItem({project, dispatch});
                          let _items = items.concat(newItems);
                          let {sales, tax, total} = quotationUtil.calcTotal(
                            _items,
                          );

                          setRecord({
                            ...record,
                            name: record.name || project.name,
                            sales,
                            tax,
                            total,
                            items: _items,
                          });
                        }}
                        customer={customer}
                      />,
                    ),
                },
              ]}>
              <ItemTable
                items={items}
                projects={projects}
                dispatches={dispatches}
                onEdit={setItem}
              />
            </Block>
          </Spin>
        </Col>
        <Col span={24}>
          {record && (
            <Spin spinning={loading || itemLoading}>
              <Row gutter={30}>
                <Col span={16}>
                  <div
                    style={{
                      padding: 20,
                      border: 'solid 0.5px #ddd',
                      borderTop: 'dashed 2px #ddd',
                    }}>
                    {isEdit && (
                      <QuotationCompleteForm
                        onSubmit={complete}
                        record={record}
                        setRecord={setRecord}
                        voidBillings={toVoid}
                      />
                    )}
                  </div>
                </Col>

                <Col span={8}>
                  <div
                    style={{
                      padding: 20,
                      border: 'solid 0.5px #ddd',
                      borderTop: 'dashed 2px #ddd',
                    }}>
                    <Field
                      label={labels['sales']}
                      style={{justifyContent: 'space-between'}}
                      render={() => (
                        <div
                          style={{
                            color: appConfig.colors.success,
                            whiteSpace: 'nowrap',
                          }}>
                          {record.sales} 元
                        </div>
                      )}
                    />
                    <Field
                      label={'0.05% ' + labels['tax']}
                      style={{justifyContent: 'space-between'}}
                      render={() => (
                        <div
                          style={{
                            color: appConfig.colors.success,
                            whiteSpace: 'nowrap',
                          }}>
                          {record.tax} 元
                        </div>
                      )}
                    />
                    <Field
                      label={labels['total']}
                      style={{justifyContent: 'space-between'}}
                      render={() => (
                        <div
                          style={{
                            color: appConfig.colors.success,
                            whiteSpace: 'nowrap',
                          }}>
                          {record.total} 元
                        </div>
                      )}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}>
                      {isEdit && (
                        <Button
                          type="default"
                          onClick={newVersion}
                          style={{marginRight: 10}}>
                          新版本
                        </Button>
                      )}
                      <Button onClick={isEdit ? edit : create}>
                        {isEdit ? '儲存' : '新增'}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Spin>
          )}
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Block title="客戶資訊">
              {customer && <CustomerData record={customer} />}
            </Block>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={billingLoading}>
            <Block title="相關帳務" contentStyle={{padding: 0}}>
              <TableWrapper>
                <Table
                  rowKey="id"
                  columns={[
                    {
                      title: Billing.labels['deadline'],
                      key: 'deadline',
                      dataIndex: 'deadline',
                      render: (deadline) => date.format(deadline, 'YYYY-MM-DD'),
                    },
                    {
                      title: '所屬人',
                      key: 'id',
                      render: (record) =>
                        record.customer ? (
                          <div>
                            <div>{Billing.labels['customer_name']}</div>
                            <div>{record.customer_name}</div>
                          </div>
                        ) : (
                          <div>
                            <div>{Billing.labels['supplier_name']}</div>
                            <div>{record.supplier_name}</div>
                            <div>{Billing.labels['dispatch_display_id']}</div>
                            <div>{record.dispatch_display_id}</div>
                          </div>
                        ),
                    },
                  ]}
                  dataSource={billings}
                  pagination={false}
                />
              </TableWrapper>
            </Block>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={loading}>
            <Block title="印製項目" contentStyle={{padding: 0}}>
              <TableWrapper>
                <Table
                  rowKey="id"
                  rowClassName={(record) =>
                    record.is_deleted ? 'deleted' : ''
                  }
                  columns={[
                    {
                      title: ProjectItem.labels['id'],
                      key: 'id',
                      render: (record) => (
                        <div>
                          <div style={{color: '#999'}}>
                            {projects[record.project].name}
                          </div>
                          <div
                            style={{
                              textDecoration: record.is_deleted
                                ? 'line-through'
                                : 'unset',
                            }}>
                            {`#${fillZero(record.id + 1)} ${record.name}`}
                          </div>
                        </div>
                      ),
                    },
                    {
                      title: '尺寸',
                      key: 'complete_size',
                      render: (record) => (
                        <div>
                          <div>{ProjectItem.labels['complete_size']}</div>
                          <div>{record.complete_size}</div>
                          <div>{ProjectItem.labels['expand_size']}</div>
                          <div>{record.expand_size}</div>
                        </div>
                      ),
                    },
                    {
                      title: ProjectItem.labels['quantity'],
                      key: 'quantity',
                      render: (record) => (
                        <div>
                          {record.quantity}
                          {record.unit}
                        </div>
                      ),
                    },
                  ]}
                  dataSource={Object.values(projects)
                    .map((x) => x.items)
                    .reduce((sum, x) => sum.concat(x || []), [])}
                  pagination={false}
                />
              </TableWrapper>
            </Block>
          </Spin>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;

const TableWrapper = styled.div`
  & .ant-table-row.deleted {
    color: #ccc;
  }
`;
