import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import Quotation from '../Models/Quotation';
import formatValidator from '../Utils/formatValidator';
import {date} from '../Utils';
import {Row, Col} from 'antd';

const {isNotEmpty} = formatValidator;

const LABELS = Quotation.labels;

export default function QuotationForm({
  record: _record,
  setRecord: _setRecord,
}) {
  const [users, setUsers] = useState([]);
  const {
    renderText,
    renderInput,
    renderDateTime,
    renderTextArea,
    renderCustom,
    renderCheckbox,
    renderSelect,
    record,
  } = useForm({record: _record, onUpdate: _setRecord});

  const app = useContext(Context);
  const isEdit = !!record.id;

  useEffect(() => {
    (async () => {
      try {
        let resp = await app.actions.getUsers();
        setUsers(resp);
      } catch (err) {}
    })();
  }, [app.actions]);

  return (
    <Wrapper>
      <div className="content">
        <Row gutter={[15]}>
          <Col span={12}>{renderText(LABELS['display_id'], 'display_id')}</Col>
          <Col span={12}>{renderText(LABELS['version'], 'version')}</Col>
          <Col span={24}>
            {renderInput(LABELS['name'], 'name', {
              extraProps: {
                placeholder: '建議填上專案名稱，或點擊新增項目自動帶入',
              },
            })}
          </Col>
          <Col span={12}>
            {renderCustom(LABELS['created'], 'created', {
              render: ({value}) => <p>{date.format(value)}</p>,
            })}
          </Col>
          <Col span={12}>
            {renderSelect(LABELS['staff_fullname'], 'staff', {
              options: users.map((u) => ({
                label: u.data?.fullname || u.username,
                value: u.id,
              })),
              onDepend: (id) => {
                let user = users.find((u) => u.id === id);
                return {
                  staff_name: user?.username,
                  staff_fullname: user?.data?.fullname,
                };
              },
            })}
          </Col>

          <Col span={12}>
            {renderDateTime(LABELS['quote_date'], 'quote_date')}
          </Col>
          <Col span={12}>
            {renderDateTime(LABELS['delivery_date'], 'delivery_date')}
          </Col>
          {/* <Col span={12}>
            {renderCheckbox(LABELS['is_deal'], 'is_deal', {
              hint: record.is_deal ? (
                <span style={{fontSize: 20, marginLeft: 10, color: '#b81212'}}>
                  🎊 賀 🎊
                </span>
              ) : (
                ''
              ),
            })}
          </Col>
          <Col span={12}>
            {renderDateTime(LABELS['deal_date'], 'deal_date')}
          </Col> */}
          <Col span={24}>{renderTextArea(LABELS['note'], 'note')}</Col>
        </Row>
        <div style={{color: '#bbb', fontSize: 12}}>
          注意: 流水號由報價單總數計算，請勿隨意由後端刪除
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > .content {
    flex: 1;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding-top: 20px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
