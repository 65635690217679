import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import {Row, Col, Table, message, Spin, Tag, Form} from 'antd';
import {Context} from '../../AppContext';
import Quotation from '../../Models/Quotation';
import {fillZero} from '../../Utils';
import ProjectItem from '../../Models/ProjectItem';
import {Edit, Check, Close} from '@styled-icons/material';
import {
  Input,
  TextArea,
  Select,
  SelectOption,
  Checkbox,
  DateTimePicker,
  Button,
} from '../../Widgets';
import {Link} from 'gatsby';
const appConfig = require('../../data.json');

function EditCell({
  isEditing,
  children,
  record,
  dataIndex,
  title,
  onEdit,
  ...props
}) {
  if (!isEditing) {
    return <td {...props}>{children}</td>;
  }

  let value;

  // if (dataIndex === 'unit_price') {
  //   return (
  //     <td {...props}>
  //       <Form.Item name={dataIndex} style={{margin: 0}}>
  //         <div style={{textDecoration: 'line-through'}}>{record.unit_cost}</div>
  //         <Input
  //           type="number"
  //           value={record[dataIndex]}
  //           onChange={(e) => {
  //             let value = Number(e.target.value);
  //             onEdit(props.index, {[dataIndex]: value});
  //           }}
  //         />
  //       </Form.Item>
  //     </td>
  //   );
  // }

  // if (dataIndex === 'price') {
  //   return (
  //     <td {...props}>
  //       <Form.Item name={dataIndex} style={{margin: 0}}>
  //         <div style={{textDecoration: 'line-through'}}>{record.cost}</div>
  //         <Input
  //           type="number"
  //           value={record[dataIndex]}
  //           onChange={(e) => {
  //             let value = Number(e.target.value);
  //             onEdit(props.index, {[dataIndex]: value});
  //           }}
  //         />
  //       </Form.Item>
  //     </td>
  //   );
  // }

  if (dataIndex === 'times') {
    value = record[dataIndex];
    return (
      <td {...props}>
        <Input
          type="number"
          value={value}
          onChange={(e) => {
            let value = Number(e.target.value);
            onEdit(props.index, {[dataIndex]: value});
          }}
        />
      </td>
    );
  }

  return (
    <td {...props}>
      <Input
        value={record[dataIndex]}
        onChange={(e) => onEdit(props.index, {[dataIndex]: e.target.value})}
      />
    </td>
  );
}

export default function ItemTable({
  items: _items,
  projects,
  dispatches,
  onEdit,
}) {
  const app = useContext(Context);
  const [editIndex, setEditIndex] = useState(null);
  const isEditing = (index) => index === editIndex;

  const items = useMemo(() => {
    return _items.map((x) => ({
      ...x,
      name: projects[x.project]
        ? projects[x.project].items[x.item_index].name
        : '-',
      project_name: projects[x.project] ? projects[x.project].name : '-',
      dispatch_display_id: dispatches[x.dispatch]
        ? dispatches[x.dispatch].display_id
        : '-',
      quantity: projects[x.project]
        ? projects[x.project].items[x.item_index].quantity
        : 0,
    }));
  }, [_items, projects, dispatches]);

  const columns = useMemo(() => {
    let result = [
      {
        title: Quotation.Item.labels['item_index'],
        render: (record) => <div>{fillZero(record.item_index + 1)}</div>,
      },
      {
        title: ProjectItem.labels['name'],
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: Quotation.Item.labels['project'],
        key: 'project',
        dataIndex: 'project_name',
      },
      {
        title: Quotation.Item.labels['dispatch'],
        key: 'dispatch',
        render: (record) => (
          <Link to={`/dispatch?id=${record.dispatch}`}>
            {record.dispatch_display_id}
          </Link>
        ),
        // dataIndex: 'dispatch_display_id',
      },
      {
        title: '成本 / ' + Quotation.Item.labels['unit_price'],
        dataIndex: 'unit_price',
        key: 'unit_price',
        render: (text, record, index) => (
          <div>
            <div style={{color: '#999'}}>{record.unit_cost}</div>
            <div>{record.unit_price}</div>
          </div>
        ),
        // editable: true,
      },
      {
        title: Quotation.Item.labels['quantity'],
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: '成本 / ' + Quotation.Item.labels['price'],
        dataIndex: 'price',
        key: 'price',
        render: (text, record, index) => (
          <div>
            <div style={{color: '#999'}}>{record.cost}</div>
            <div>{record.price}</div>
          </div>
        ),
        // editable: true,
      },
      {
        title: Quotation.Item.labels['times'],
        dataIndex: 'times',
        key: 'times',
        editable: true,
      },
      {
        title: '編輯',
        render: (text, record, index) =>
          isEditing(index) ? (
            <div style={{display: 'flex'}}>
              <Action
                onClick={() => {
                  setEditIndex(null);
                }}>
                <Check size="16" color="#505050" />
              </Action>
            </div>
          ) : (
            <Action
              onClick={() => {
                setEditIndex(index);
              }}>
              <Edit size="16" color={appConfig.colors.success} />
            </Action>
          ),
      },
    ];

    return result.map((col) => {
      if (col.editable) {
        return {
          ...col,
          onCell: (record, index) => ({
            record,
            index,
            dataIndex: col.dataIndex,
            isEditing: isEditing(index),
            onEdit,
            title: col.title,
          }),
        };
      }

      return col;
    });
  }, [projects, dispatches, editIndex]);

  return (
    <Table
      rowKey={(record) => `${record.item_index} ${record.dispatch}`}
      columns={columns}
      dataSource={items}
      pagination={false}
      components={{body: {cell: EditCell}}}
    />
  );
}

const Action = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
`;
