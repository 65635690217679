import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import useForm from '../hooks/use-form';
import styled from 'styled-components';
import Quotation from '../Models/Quotation';
import formatValidator from '../Utils/formatValidator';
import {date} from '../Utils';
import {Row, Col, message} from 'antd';
import {Button} from '../Widgets';

const {isNotEmpty} = formatValidator;

const LABELS = Quotation.labels;

export default function QuotationForm({
  record: _record,
  setRecord: _setRecord,
  onSubmit,
  voidBillings,
}) {
  const {
    renderText,
    renderInput,
    renderDateTime,
    renderTextArea,
    renderCustom,
    renderCheckbox,
    record,
  } = useForm({record: _record, onUpdate: _setRecord});

  const app = useContext(Context);
  const isEdit = !!record.id;

  return (
    <Wrapper>
      <div className="content">
        <Row gutter={[15]}>
          <Col span={16}>
            {renderDateTime(LABELS['deal_date'], 'deal_date')}
          </Col>
          <Col span={8} style={{textAlign: 'right'}}>
            <Button
              disabled={record.is_deal || record.void}
              onClick={() => {
                if (
                  window.confirm('確定報價單成交？將會建立客戶及廠商之帳務')
                ) {
                  onSubmit({
                    deal_date: record.deal_date,
                    is_deal: true,
                  });
                }
              }}>
              {record.is_deal ? '已成交 🎉' : '成交'}
            </Button>
            {/* {renderCheckbox(LABELS['is_deal'], 'is_deal', {
              hint: record.is_deal ? (
                <span style={{fontSize: 20, marginLeft: 10, color: '#b81212'}}>
                  🎊 賀 🎊
                </span>
              ) : (
                ''
              ),
            })} */}
          </Col>
          <div style={{color: '#bbb', fontSize: 12, padding: '0 7.5px'}}>
            注意：成交後，會建立客戶以及廠商的帳務
          </div>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button
              disabled={record.void}
              onClick={() => {
                if (window.confirm('確定取消報價單？相關的帳務也會被取消')) {
                  message.warning('未完成');
                  onSubmit({void: true});

                  voidBillings();
                }
              }}>
              作廢
            </Button>
          </Col>
          <div style={{color: '#bbb', fontSize: 12, padding: '0 7.5px'}}>
            注意：取消後，帳務失效，請重新再建立報價單或建立新版本
          </div>
        </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > .content {
    flex: 1;
  }

  & > .footer {
    border-top: solid 1px #ddd;
    padding-top: 20px;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
