import React, {useContext, useState, Fragment} from 'react';
import {Add} from '@styled-icons/material';
import {Button} from '../Widgets';
import SearchList from './SearchList';
import {Context} from '../AppContext';
import Project from '../Models/Project';
import Dispatch from '../Models/Dispatch';
import {Tag} from 'antd';

export default function DispatchSelect({record, setRecord, customer}) {
  const [selectedProject, setSelectedProject] = useState(null);
  const app = useContext(Context);

  if (!customer) {
    return null;
  }

  if (selectedProject) {
    return (
      <Fragment>
        <div style={{padding: '20px 20px 0 20px'}}>
          <Tag
            closable
            color="volcano"
            onClose={() => setSelectedProject(null)}>
            {selectedProject.name}
          </Tag>
        </div>
        <SearchList
          query={{project: selectedProject.id}}
          getRecords={app.actions.getDispatchs}
          fields={['display_id', 'version']}
          labels={Dispatch.labels}
          title={`選擇工單`}
          onClick={(option) => {
            setRecord({
              project: selectedProject,
              dispatch: option,
            });
            app.actions.setModal();
            setSelectedProject(null);
          }}
          value={null}
        />
      </Fragment>
    );
  }

  return (
    <SearchList
      query={{owner: customer.id}}
      getRecords={app.actions.getProjects}
      fields={['name']}
      labels={Project.labels}
      title={`選擇專案`}
      onClick={(option) => {
        setSelectedProject(option);
      }}
      value={null}
    />
  );
}
